<template>
  <div>
    <h1>Curation Dashboard</h1>
    <img src="@/assets/amico.png" alt="" />
    <p>Access your dashboard to curation system here</p>
    <div class="text-center">
      <div class="inline-block w-80 mt-6 mb-16">
        <button class="button disabled">Coming Soon</button>
        <!--<Button buttonText="Open Curation Dashboard" size="full" @action="talent()"/>-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button')
  },
  methods: {
    talent() {
      window.open('https://talent.g2academy.co/login-partner')
    }
  },
  computed: {
    ...mapGetters('customer', ['customer'])
  }
}
</script>
<style lang="scss" scoped>
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  width: 150px;
  height: 150px;
}
h1 {
  margin-top: 20px;
  font-weight: bold;
  font-size: 32px;
}
p {
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-top: 60px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}
.button {
  background-color: #a9a9a9; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  width: 300px;
  height: 38px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
